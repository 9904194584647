import React from "react";

export default function ChatMessage({ message }) {
  if (message.text === undefined) {
    console.log("message.voice.source", JSON.stringify(message.voice.source));
  }

  return (
    <>
      <div className="chat-message" key={message.id}>
        <div
          className={`flex items-end ${message.isSender ? "justify-end" : ""}`}
        >
          <div
            className={`flex flex-col space-y-2 text-xs max-w-xs mx-2 ${
              message.isSender ? "order-1 items-end" : "order-2 items-start"
            }`}
          >
            <div>
              {message.voice && message.voice.src ? (
                <audio controls src={message.voice.src}>
                  Your browser does not support the audio element.
                </audio>
              ) : (
                <span
                  className={`px-4 py-2 rounded-lg inline-block ${
                    message.isSender
                      ? "bg-blue-600 text-white rounded-br-none"
                      : "bg-gray-300 text-gray-600 rounded-bl-none"
                  }`}
                >
                  {message.text}
                </span>
              )}
            </div>
          </div>
          <img
            src={message.senderProfile}
            alt={message.sender}
            className={`w-6 h-6 rounded-full ${
              message.isSender ? "order-2" : "order-1"
            }`}
          />
        </div>
      </div>
    </>
  );
}
