import React, { useEffect, useState } from "react";
import { AudioRecorder } from "react-audio-voice-recorder";

export default function Recorder({ messages, setMessages }) {
  console.log("messages==>", messages);
  const [apiMessage, setApiMessage] = useState("");

  const addAudioElement = (blob) => {
    const url = URL.createObjectURL(blob);
    console.log("url==>", url);
    const audio = document.createElement("audio"); // Corrected element type
    audio.src = url;
    audio.controls = true;
    // document.body.appendChild(audio);
    addNewMessage({ src: url, controls: true });

    handleSubmit(blob);
  };

  const handleSubmit = async (blob) => {
    var d = new Date();

    const formdata = new FormData();
    console.log("blob==>", blob);
    formdata.append(
      "file",
      blob,
      `${d.getHours()}:${d.getMinutes()}:${d.getSeconds()}`
    );

    const requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    };

    try {
      const response = await fetch(
        "http://172.16.10.59:5000/process_audio",
        requestOptions
      );
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const result = await response.text();
      setApiMessage(result); // Update state with API response
    } catch (error) {
      console.error("Error during fetch:", error);
      setApiMessage("Error occurred while sending the recording.");
    }
  };

  const addNewMessage = (message) => {
    setMessages((prev) => {
      return [
        ...prev,
        {
          id: Math.floor(Math.random(0, 9) * 100),
          text: undefined,
          voice: message,
          sender: "David",
          senderProfile:
            "https://images.unsplash.com/photo-1590031905470-a1a1feacbb0b?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=3&w=144&h=144",
          isSender: true,
        },
      ];
    });
  };
  useEffect(() => {
    // addNewMessage("new message");
  }, []);
  return (
    <div>
      <AudioRecorder
        classes="border border-red-900"
        onRecordingComplete={addAudioElement}
        audioTrackConstraints={{
          noiseSuppression: true,
          echoCancellation: true,
        }}
        onNotAllowedOrFound={(err) => console.table(err)}
        downloadOnSavePress={false}
        downloadFileExtension="wav"
        mediaRecorderOptions={{
          audioBitsPerSecond: 128000,
        }}
      />
      <br />
      {/* Display the API response message */}
    </div>
  );
}
